import React, { useState, useEffect } from "react"
import { useNavigate, useLocation } from 'react-router-dom'
import { Grid, Box, TextField, Typography, FormGroup, Button } from '@mui/material'
import axios from 'axios'
import AdminLayout from "../AdminLayout"
import swal from 'sweetalert'

export default function EditUser() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const segments = pathname.split('/').filter(Boolean);
  const userId = segments[segments.length - 1];

  const [formInput, setFormInput] = useState({
    id: '',
    name: '',
    email: '',
    password: '',
  });

  useEffect(() => {
    getProfileDetails();

  }, [localStorage.getItem('auth_id')]);

  const handleInput = (e) => {
    e.persist();
    setFormInput({ ...formInput, [e.target.name]: e.target.value });
  }

  const getProfileDetails = () => {
    axios.get(`/api/get-profile-data/${userId}`).then(res => {
      if (res.data.status === 200) {
        setFormInput(res.data.get_data[0]);
      }
      else if (res.data.status === 404) {
        console.log(res.message);
      }
    });
  }

  const userUpdate = (e) => {
    e.preventDefault();
    const data = {
      id: userId,
      name: formInput.name,
      email: formInput.email,
      password: formInput.password,
    }
    axios.get('sanctum/csrf-cookie').then(response => {
      axios.put('/api/update-user-profile', data).then(res => {
        if (res.data.status === 200) {
          swal("Success", "Profile successfully updated", "success");
        }
        else {
          setFormInput({ ...formInput, error_list: res.data.validation_errors });
        }
      });
    });

  }

  return (
    <AdminLayout title={"Settings"}>
      <Typography className='adm-page-title'>Edit User</Typography>

      <Box component={"form"} onSubmit={userUpdate}>
        <FormGroup className="form-group">
          <TextField
            type='text'
            fullWidth
            label="Name"
            name="name"
            onChange={handleInput}
            value={formInput.name || ''}
          />
        </FormGroup>
        <FormGroup className="form-group">
          <TextField
            type='text'
            fullWidth
            label="Email"
            name="email"
            onChange={handleInput}
            value={formInput.email || ''}
          />
        </FormGroup>
        <FormGroup className="form-group">
          <TextField
            type='password'
            fullWidth
            label="Password"
            name="password"
            onChange={handleInput}
            value={formInput.password || ''}
          />
        </FormGroup>

        <Button
          fullWidth
          variant={"outlined"}
          type={"submit"}
          className="adm_theme-btn"
        >Update User</Button>
      </Box>

    </AdminLayout>
  )
}
