import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { TableContainer, Paper, Table, TableBody, TableHead, TableRow, TableCell, Typography } from '@mui/material'
import MasterLayout from "../../dashboard/MasterLayout"
import UserMenu from '../../dashboard/pages/UserMenu'
import AdminLayout from '../AdminLayout'

export default function Users() {
  const [appointmentList, setAppointmentList] = useState([]);

	useEffect(() => {
    getUsers();

  }, []);

  const getUsers = () => {
		axios.get(`/api/get-customers`).then(res => {
			if(res.data.status === 200) {
				setAppointmentList(res.data.get_data);
			}
		});
	}

  const updateUserProfile = () => {
    // e.preventDefault();
    // const store_id = localStorage.getItem('store_id');
    // const data = formInput;
    // axios.put(`/api/update-user-profile/${store_id}`, data).then(res => {
    //   if (res.data.status === 200) {
    //     console.log(res.data.message);
    //     setError([]);
    //   }
    //   else if (res.data.status === 422) {
    //     setError(res.data.errors);
    //   }
    //   else {
    //     console.log(res.data.errors);
    //   }
    // });
  }

  return (
    <AdminLayout title={"Users"}>
      <Typography className='adm-page-title'>Users</Typography>
      
      <UserMenu />
      
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appointmentList.map((row, item) => (
              <TableRow
                key={item}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{item + 1}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>
                  <Link className="table-btn" to={`/admin/edit-user/${row.id}`}>EDIT</Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </AdminLayout>
  )
}
