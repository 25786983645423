import React, { useState, useEffect } from "react";
import { Grid, Box, TextField, Typography, FormGroup, Button } from '@mui/material'
import axios from 'axios'
import MasterLayout from "../MasterLayout"
import { useLocation } from "react-router-dom"
import swal from 'sweetalert'

export default function AccountSettings() {
  //const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const segments = pathname.split('/').filter(Boolean);

  const [formInput, setFormInput] = useState({
    id: '',
    name: '',
    email: '',
    password: '',
  });

  useEffect(() => {
    getProfileDetails();

  }, [localStorage.getItem('auth_id')]);

  const getProfileDetails = () => {
    const auth_id = localStorage.getItem('auth_id');
    axios.get(`/api/get-profile-data/${auth_id}`).then(res => {
      if (res.data.status === 200) {
        setFormInput(res.data.get_data[0]);
      }
      else if (res.data.status === 404) {
        console.log(res.message);
      }
    });
  }

  const handleInput = (e) => {
    e.persist();
    setFormInput({ ...formInput, [e.target.name]: e.target.value });
  }

  const updateProfile = (e) => {
    e.preventDefault();
    const data = {
      id: localStorage.getItem('auth_id'),
      name: formInput.name,
      email: formInput.email,
      password: formInput.password,
    }
    axios.get('sanctum/csrf-cookie').then(response => {
      axios.put('/api/update-user-profile', data).then(res => {
        if (res.data.status === 200) {
          swal("Success", "Profile successfully updated", "success");
        }
        else {
          setFormInput({ ...formInput, error_list: res.data.validation_errors });
        }
      });
    });

  }

  return (
    <MasterLayout title={"Settings"}>
      <Box component={"form"} onSubmit={updateProfile}>
        <Typography className='adm-page-title'>Settings</Typography>
        <FormGroup className="form-group">
          <TextField
            type='text'
            fullWidth
            label="Name"
            name="name"
            InputProps={{
              readOnly: true,
            }}
            onChange={handleInput}
            value={formInput.name || ''}
          />
        </FormGroup>
        <FormGroup className="form-group">
          <TextField
            type='text'
            fullWidth
            label="Email"
            name="email"
            InputProps={{
              readOnly: true,
            }}
            onChange={handleInput}
            value={formInput.email || ''}
          />
        </FormGroup>
        <FormGroup className="form-group">
          <TextField
            type='password'
            fullWidth
            label="Password"
            name="password"
            onChange={handleInput}
            value={formInput.password || ''}
          />
        </FormGroup>
        <Button
          variant={"outlined"}
          type={"submit"}
          className="adm_theme-btn"
        >Update</Button>
      </Box>
    </MasterLayout>
  )
}
