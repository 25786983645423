import React, {useEffect} from 'react'
import axios from 'axios'
import { Button, Grid, Typography, Modal, Box } from '@mui/material'
import { Link } from 'react-router-dom'
import MasterLayout from "../MasterLayout"
import { useState } from 'react'

import NZMap from '../../../assets/images/welcome-baner.jpg'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  p: '20px 25px',
};

const welcomeStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  p: '20px 25px',
};

export default function HomePage() {
  const [storeList, setStoreList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const [welcomeOpen, setWelcomeOpen] = React.useState(false);
  const handleWelcomeClose = () => setWelcomeOpen(false); 

  useEffect(() => {
    getStoreList();

  }, []);

  const getStoreList = () => {
    axios.get(`/api/get-stores`).then(res => {
      if(res.data.status === 200) {
        setStoreList(res.data.stores);
      }
      else {
        console.log(res.data.errors, "error");
      }
    });
  }

  const handleWelcomeOpen = (e) => {
    setWelcomeOpen(true);
  }

  const viewSchoolList = (e, id) => {
    axios.get(`/api/get-schools/${id}`).then(res => {
      if (res.data.status === 200) {
        setSelectedSchool(res.data.store_name);
        setSchoolList(res.data.get_data);
      }
    });
    setOpen(true);
  }

  return (
    <MasterLayout title={"Home page"}>
      <div className="welcome-wrap">
        <Grid container className='welcome_wrap'>
          <Grid item sm={12} md={8}>
            <Grid className='col_title' mt={2}>
              <Typography variant='h4'>Book Your <br />Meeting Room Today</Typography>  
            </Grid>

            <Grid container spacing={2}>
              {storeList.map((row, i) => (
                <Grid key={i} item sm={4} md={3} mt={0}>
                  <Link to={row.slug} className="store-card">
                    <div className='link-icon'></div>
                    <Typography className='store-name'>{row.store_name}</Typography>   
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {/* <Grid item sm={4} pl={5} sx={{display: { md: 'block', sm: 'none'} }}>
            <div className='map_warp'>    
              <img src={NZMap} />
            </div>
          </Grid> */}
        </Grid>
        <Grid container>
          <a href='/#/admin' className='theme-btn login-btn'>Login</a>
        </Grid>
      </div>  
    </MasterLayout>
  )
}
